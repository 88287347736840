import ElementAssets from './ElementAssets';
import EnemyList from './EnemyList';
import { keys, without } from 'lodash';
import AttackAssets from './AttackAssets';
import AttackLabels from './AttackLabels';
import AbyssFloors from './AbyssFloors';
import FARMABLE_DOMAINS from './FarmableDomains';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const REGIONS = [
  'America',
  'China',
  'Europe',
  'Asia',
  'TW/HK/MO',
  'Unknown'
];

export const ATTACKS = keys(AttackAssets);
export const ATTACK_LABELS = AttackLabels;
export const ELEMENT_ICONS = ElementAssets;

// TODO: Check & Update ABYSS_VERSION_HISTORY after every patch.
// ABYSS_VERSION_HISTORY is used to retrieve past rankings for the SpeedrunLeaderboard
export const ABYSS_VERSION_HISTORY = [
  '1.6',
  '2.0',
  '2.1',
  '2.2',
  '2.3',
  '2.4',
  '2.5',
  '2.6',
  '2.7',
  '2.8',
  '3.0',
  '3.1',
  '3.2',
  '3.3',
  '3.4',
  '3.5',
  '3.6',
  '3.7',
  '3.8',
  '4.0',
  '4.1',
  '4.2',
  '4.3',
  '4.4',
  '4.5',
  '4.6',
  '4.7',
  '4.8',
  '5.0'
];

// TODO: Check & Update CURRENT_ABYSS_VERSION on abyss update
export const CURRENT_ABYSS_VERSION = ABYSS_VERSION_HISTORY.at(-1);
export const SPEEDRUN_ABYSS_CHAMBERS = [
  '12-1-1',
  '12-1-2',
  '12-2-1',
  '12-2-2',
  '12-3-1',
  '12-3-2',
  '12-1',
  '12-2',
  '12-3',
  '12-top',
  '12-bot',
  '12-all'
];
export const WEEKLY_BOSSES = EnemyList.WEEKLY_BOSSES;
export const ALL_MOBS = EnemyList.ALL_MOBS;
export const DPS_WEEKLY_BOSSES = EnemyList.WEEKLY_BOSSES;
export const WORLD_BOSSES = EnemyList.WORLD_BOSSES;
export const OVERWORLD_MOBS = EnemyList.OVERWORLD_MOBS;
export const DPS_CATEGORY = [
  'Abyss',
  'Domain',
  'Event',
  'Reputation Bounty',
  'Weekly Boss',
  'World Boss',
  'Overworld'
];
export const SPEEDRUN_CATEGORIES = [
  'Abyss',
  'Domain',
  'Event',
  'Weekly Boss',
  'World Boss'
];
export const ABYSS_FLOORS = AbyssFloors;

// All Filterable Domains on leaderboard
export const DOMAINS = FARMABLE_DOMAINS;
const NonSubmittableDomains = [];
// Domains available for submission
export const SUBMITTABLE_DOMAINS = without(DOMAINS, ...NonSubmittableDomains);

// All filterable DPS Events on leaderboard
export const DPS_EVENTS = [
  'A Study in Potions',
  'Energy Amplifier Fruition',
  'Theater Mechanicus',
  'Phantom Flow',
  'Fellflower',
  'Labyrinth Warriors',
  'Bantan Sango Case Files: The Warrior Dog',
  'Legend of the Vagabond Sword',
  'Second Blooming'
];

const NonSubmittableDPSEvents = [];
// DPS events that are available for submission
export const SUBMITTABLE_DPS_EVENTS = without(
  DPS_EVENTS,
  ...NonSubmittableDPSEvents
);

// All filterable Speedrun Events on leaderboard
export const EVENTS = [
  'The Chasm Call - F2P',
  'The Chasm Call - Unrestricted',
  '2.4 A Study in Potions',
  '2.3 Energy Amplifier Fruition',
  '2.0 Phantom Flow',
  '1.6 Legend of the Vagabond Sword'
];

const NonSubmittableEvents = [
  'The Chasm Call - F2P',
  'The Chasm Call - Unrestricted'
];
// Speedrun events that are available for submission
export const SUBMITTABLE_SPEEDRUN_EVENTS = without(
  EVENTS,
  ...NonSubmittableEvents
);

export const ARTIFACT_STAT_TO_TEXT = {
  'Energy Recharge': 'Energy R.',
  'HP Percentage': 'HP %',
  'HP Flat': 'HP',
  'HP Base': 'HP',
  'HP Max': 'HP',
  'Defense Percentage': 'DEF %',
  'Defense Flat': 'DEF',
  'Defense Base': 'DEF',
  'Defense Current': 'DEF',
  'Attack Percentage': 'ATK %',
  'Attack Flat': 'ATK',
  'Attack Base': 'ATK',
  'Attack Current': 'ATK',
  'Critical Strike Chance': 'CRIT Rate',
  'Critical Strike Multiplier': 'CRIT DMG',
  'Elemental Mastery': 'Elem. Mastery',
  'Physical Damage Bonus': 'Phys. DMG Bonus',
  'Pyro Damage Bonus': 'Pyro DMG Bonus',
  'Geo Damage Bonus': 'Geo DMG Bonus',
  'Dendro Damage Bonus': 'Dendro DMG Bonus',
  'Cryo Damage Bonus': 'Cryo DMG Bonus',
  'Electro Damage Bonus': 'Electro DMG Bonus',
  'Anemo Damage Bonus': 'Anemo DMG Bonus',
  'Hydro Damage Bonus': 'Hydro DMG Bonus',
  'Healing Bonus': 'Healing Bonus'
};

export const TOKEN_STORAGE_KEY = `tgh_token_storage_${process.env.REACT_APP_ENVIRONMENT}`;

export const ABYSS_CHAMBER_CONTENT = {
  '12-1-1': ['12-1-1'],
  '12-1-2': ['12-1-2'],
  '12-2-1': ['12-2-1'],
  '12-2-2': ['12-2-2'],
  '12-3-1': ['12-3-1'],
  '12-3-2': ['12-3-2'],
  '12-1': ['12-1-1', '12-1-2'],
  '12-2': ['12-2-1', '12-2-2'],
  '12-3': ['12-3-1', '12-3-2'],
  '12-top': ['12-1-1', '12-2-1', '12-3-1'],
  '12-bot': ['12-1-2', '12-2-2', '12-3-2'],
  '12-all': ['12-1-1', '12-1-2', '12-2-1', '12-2-2', '12-3-1', '12-3-2']
};
